import { useContext, useEffect, useMemo, useState } from 'react';
import { Checkbox, TextInput, Button, Tabs, Switch, Space, NumberInput } from '@mantine/core';
import MantineTable from '../../../../common/table/mantine';
import { axios } from '../../../../utils/axios';
import { useParams } from 'react-router-dom';
import { IconRefresh, IconTrash } from '@tabler/icons-react';
import { CostingRequestsContext, useCrStatusCancelled } from '../../../../contexts/CostingRequest';
import { showAlert } from '../../../../utils/alerts';
import { ProjectOverview } from '../projectOverview';
import ContentPaper from '../../../../components/layouts/rfs/Content';
import styled from '@emotion/styled';
import { IconPlus } from '@tabler/icons-react';
import { formatSkus, checkIfFlagged, checkZeroQty } from './helper';
import { createStyles } from '@mantine/core';

const FooterContainer = styled.div`
  float: right;
  button {
    width: 200px;
  }
`;

const CenterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const initialData = {
  delete_component: [],
  update_component: [],
  add_component: [],
};

export const useSwitchStyles = createStyles((theme) => ({
  input: {
    outline: '2px solid black',
    outlineOffset: '-1px',
  },
  thumb: {
    color: 'black',
    backgroundColor: 'black',
  },
  track: {
    border: '3px solid black',
    margin: '1px',
  },
}));

export const BudgetBomAdjustment = ({ setLogsModal, activeTab }) => {
  const isCancelled = useCrStatusCancelled();
  const { crNumber } = useParams();

  const { info, crUserPermissions } = useContext(CostingRequestsContext);
  const [loading, setLoading] = useState(false);
  const [switchState, setSwitchState] = useState(false);
  const [baseSkus, setBaseSkus] = useState([]);
  const [componentsDict, setComponentsDict] = useState(initialData);
  const [activePlant, setActivePlant] = useState();
  const [skuID, setSkuID] = useState();
  const { producing_plant_data = [], sku_data = [] } = info;
  const [btnDisable, setBtnDisable] = useState(false);


  useEffect(() => {
    if (activeTab === 'budgetBomAdjustment' && sku_data.length && producing_plant_data.length) {
      fetch();
      setActivePlant(producing_plant_data[0]?.producing_plant);
    }
  }, [activeTab, producing_plant_data, sku_data]);

  useEffect(() => {
    setComponentsDict(initialData);
    fetch();
  }, [activePlant]);

  const { classes } = useSwitchStyles();
  const checkDiff = (main, reference) => {
    const diff =
      parseFloat(main) && parseFloat(reference)
        ? parseFloat(main) !== parseFloat(reference)
        : false;
    const difference = parseFloat(main) - parseFloat(reference) || 0;
    return { diff: diff, variance: parseFloat(difference).toFixed(2) };
  };

  const fetch = async (refresh = false) => {
    setLoading(true);
    try {
      if (refresh) await axios.get(`/costing-request/step/refresh-bom-adjustment/${crNumber}`);
      const { data } = await axios.get(`/costing-request/step/bom-adjustment-data/${crNumber}`);
      const skus = formatSkus(data.requestsku, data.referencesku);
      
      handleSubmitStatus(data.stepStatus, data?.is_vic_submission);

      setBaseSkus(skus);
      const withSkuID = data.requestsku.filter((ele) => ele.sku_id);
      setSkuID(withSkuID?.[0]?.sku_id);
    } catch (e) {
      console.log(e);
      showAlert('no data available', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitStatus = (step, vicSubStatus, plant = activePlant) => {
    if (Array.isArray(step) && step.length > 0) {
      const activePlantId = producing_plant_data.filter(
        (ele) => ele.producing_plant === String(plant),
      )?.[0]?.id;
      const stepsData = step.filter((ele) => ele.producing_plant_id === activePlantId);
      const vicState = vicSubStatus.filter(ele => ele?.int_cr_producing_plant?.producing_plant === String(plant));
      if (Array.isArray(stepsData)) {
        (stepsData?.[0]?.status === 'In Progress' || vicState?.[0]?.status === 'In Progress') && 
        crUserPermissions[String(plant)]?.['standard/transfer_pricing'].is_accessible
          ? setBtnDisable(false)
          : setBtnDisable(true);
      }
      return;
    }
  };

  const handleMaterialAdd = (data) => {
    const primaryData = data.PrimaryRefSkuCostEstimation;
    delete data.PrimaryRefSkuCostEstimation;
    const withSkuID = baseSkus.filter((ele) => ele.sku_id);
    setSkuID(withSkuID?.[0]?.sku_id);
    const finalData = {
      ...data,
      isReference: false,
      hasReference: true,
      flagged: false,
      reference: data,
      is_add_component: true,
      req_plant_id: activePlant,
      sku_id: skuID,
      pp_id: producing_plant_data.filter((ele) => ele.producing_plant === activePlant)?.[0]?.id,
    };
    setComponentsDict((prevData) => {
      return {
        ...prevData,
        add_component: [
          ...prevData.add_component.filter((comp) => comp.component !== data.component),
          finalData,
        ],
      };
    });
    setBaseSkus((prevData) => {
      return prevData.filter((sku) => sku.component !== data.component).concat(finalData);
    });
    handleSubmit({ add_component: [finalData], delete_component: [], update_component: [] });
  };

  const handleSubmit = async (payload) => {
    const resZeroUpdate = checkZeroQty(payload.update_component);
    if (resZeroUpdate) {
      showAlert({ message: 'Some quantities are zero' }, 'error');
      return;
    }
    try {
      await axios.post(`/costing-request/step/bom-updates/${crNumber}`, payload);
      showAlert({ message: 'Request Submitted Successfully' }, 'success');
    } catch (err) {
      showAlert(err, 'error');
    } finally {
      setComponentsDict({
        delete_component: [],
        update_component: [],
        add_component: [],
      });
      fetch(true);
    }
  };

  const handleInputChange = (component, field, value, componentToUpdate) => {
    if (field === 'scrap') {
      value = Math.min(Math.max(Number(value.toFixed(3)), 0), 100);
    }
    if (field === 'quantity') {
      value = Math.min(Math.max(Number(value.toFixed(3)), -1000000), 1000000);
    }
    const updateSkuData = (data) => {
      return data.map((sku) => {
        if (sku?.component === component && sku?.component_id === componentToUpdate?.component_id) {
          let updatedSku;
          let updatedRefData;
          if (field === 'costing_relevancy') {
            updatedRefData = {
              ...sku.RequestedSkuCostEstimation,
            };
          } else {
            updatedRefData = {
              ...sku.RequestedSkuCostEstimation,
              total_value: '',
            };
          }
          updatedSku = {
            ...sku,
            RequestedSkuCostEstimation: updatedRefData,
            isChanged: true,
            [field]: value,
          };
          const isFlagged = checkIfFlagged(updatedSku, componentToUpdate.reference);
          return {
            ...updatedSku,
            flagged: isFlagged,
          };
        }
        return sku;
      });
    };
    setBaseSkus((prevDisplayData) => [...updateSkuData(prevDisplayData)]);
    setComponentsDict((prevDict) => {
      const updatedComponent = { ...componentToUpdate, [field]: value };
      return {
        ...prevDict,
        update_component: [
          ...prevDict.update_component.filter((comp) => comp.component !== component),
          updatedComponent,
        ],
      };
    });
  };

  const handleMaterialRemoval = (component, data) => {
    setBaseSkus((prevBaseSkus) => prevBaseSkus.filter((item) => item.component !== component));
    setComponentsDict((prevDict) => ({
      ...prevDict,
      delete_component: [...prevDict.delete_component, data],
    }));
  };

  const handleAutoAdjustment = () => {
    setBaseSkus((prevData) =>
      prevData.map((ele) => {
        const updatedEle = { ...ele };
        if (!ele.isReference) {
          if (ele.reference?.quantity && ele.reference?.quantity !== updatedEle.quantity) {
            updatedEle.quantity = ele.reference.quantity;
            updatedEle.isChanged = true;
            if (ele.RequestedSkuCostEstimation) {
              updatedEle.RequestedSkuCostEstimation = {
                ...ele.RequestedSkuCostEstimation,
                total_value: '',
              };
            }
          }
          if (ele.reference?.scrap && updatedEle.scrap !== ele.reference.scrap) {
            updatedEle.scrap = ele.reference.scrap;
            updatedEle.isChanged = true;
            if (ele.RequestedSkuCostEstimation) {
              updatedEle.RequestedSkuCostEstimation = {
                ...ele.RequestedSkuCostEstimation,
                total_value: '',
              };
            }
          }

          if (typeof ele.reference?.costing_relevancy === 'boolean') {
            updatedEle.costing_relevancy = ele.reference.costing_relevancy;
            updatedEle.isChanged = true;
          }

          updatedEle.flagged = checkIfFlagged(updatedEle, updatedEle.reference);
          if (updatedEle.isChanged) {
            setComponentsDict((prevDict) => {
              const updatedComponent = { ...updatedEle };
              return {
                ...prevDict,
                update_component: [
                  ...prevDict.update_component.filter(
                    (comp) => comp.component !== updatedEle.component,
                  ),
                  updatedComponent,
                ],
              };
            });
          }
          return updatedEle;
        }
        return ele;
      }),
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'actions',
        header: 'Actions',
        Cell: ({ row }) => {
          return (
            <>
              {!row.original.isReference ? (
                <CenterItem>
                  <Button
                    disabled={btnDisable || isCancelled}
                    variant='subtle'
                    color='red'
                    size='md'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleMaterialRemoval(row.original?.component, row.original);
                    }}
                  >
                    <IconTrash size={16} />
                  </Button>
                </CenterItem>
              ) : (
                <CenterItem>
                  <Button
                    disabled={btnDisable || isCancelled}
                    color='green'
                    size='md'
                    onClick={(e) => handleMaterialAdd(row.original)}
                  >
                    <IconPlus size={16} />
                  </Button>
                </CenterItem>
              )}
            </>
          );
        },
      },
      {
        accessorKey: 'componentNumber',
        header: 'Mat No',
        sticky: 'left',
      },
      {
        accessorKey: 'component_description',
        header: 'Mat Desc',
        sticky: 'left',
      },
      {
        accessorKey: 'item_category',
        header: 'ICt',
        grow: false,
        size: 5,
      },
      {
        accessorKey: 'component_unit',
        header: 'UOM',
        grow: false,
        size: 5,
      },
      {
        id: 'quantity',
        header: 'Qty',
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row.original?.quantity,
            row.original.reference?.quantity,
          );
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? `Ref:${'\xa0\xa0'}` : 'Main: '}{' '}
                </span>
                <NumberInput
                  precision={3}
                  min={0}
                  step={0.01}
                  value={parseFloat(row.original?.quantity ?? 0) ?? 0}
                  disabled={row.original.isReference || btnDisable || isCancelled}
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                      width: '100px',
                    },
                  })}
                  onChange={(value) => {
                    const newValue = value || 0;
                    handleInputChange(row.original?.component, 'quantity', newValue, row.original);
                  }}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref: </span>
                  {' \xa0\xa0 '}
                  <NumberInput
                    precision={3}
                    min={0}
                    step={0.01}
                    disabled
                    value={row.original.reference?.quantity ?? ''}
                    styles={(theme) => ({
                      input: {
                        width: '100px',
                      },
                    })}
                  />
                </div>
              )}
              {row.original.hasReference && (
                <span style={{ marginRight: '8px' }}>
                  Var:{' \xa0\xa0 '}{' '}
                  <span style={variance === '0.00' ? {} : { color: 'red' }}>
                    {Math.abs(parseFloat(variance))}
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: 'costing_relevancy_excel',
        header: 'Costing Relevancy',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Main: '}
                </span>
                <Checkbox
                  checked={row.original?.costing_relevancy ?? true}
                  disabled={row.original.isReference || btnDisable || isCancelled}
                  onChange={(e) =>
                    handleInputChange(
                      row.original?.component,
                      'costing_relevancy',
                      e.currentTarget.checked,
                      row.original,
                    )
                  }
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {' \xa0\xa0 '}
                  <Checkbox
                    checked={row.original?.reference?.costing_relevancy || false}
                    disabled
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: 'scrap',
        header: 'Scrap',
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(row.original?.scrap, row.original?.reference?.scrap);
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Main: '}
                </span>
                <NumberInput
                  precision={3}
                  min={0}
                  step={0.01}
                  disabled={row.original.isReference || btnDisable || isCancelled}
                  value={parseFloat(row.original?.scrap) ?? 0}
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                      width: '100px',
                    },
                  })}
                  onChange={(value) => {
                    const newValue = value || 0;
                    handleInputChange(row.original?.component, 'scrap', newValue, row.original);
                  }}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {' \xa0\xa0 '}
                  <NumberInput
                    precision={3}
                    min={0}
                    step={0.01}
                    disabled
                    value={parseFloat(row.original?.reference?.scrap ?? '')}
                    styles={(theme) => ({
                      input: {
                        width: '100px',
                      },
                    })}
                  />
                </div>
              )}
              {row.original.hasReference && (
                <span style={{ marginRight: '8px' }}>
                  Var:{' \xa0\xa0 '}{' '}
                  <span style={variance === '0.00' ? {} : { color: 'red' }}>
                    {Math.abs(parseFloat(variance))}
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: 'total_value_excel',
        header: 'Total Value',
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row.original?.RequestedSkuCostEstimation?.total_value,
            row.original?.reference?.PrimaryRefSkuCostEstimation?.total_value,
          );
          const totalValue = row.original?.isReference
            ? row.original?.PrimaryRefSkuCostEstimation?.total_value
            : row.original?.RequestedSkuCostEstimation?.total_value;
          return (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span style={{ marginRight: '8px' }}>
                  {row.original.isReference ? 'Ref' : 'Main: '}{' '}
                </span>
                <TextInput
                  value={totalValue ?? ''}
                  disabled
                  styles={(theme) => ({
                    input: {
                      borderColor: diff ? 'red' : theme.colors.gray[4],
                      borderWidth: '1px',
                      width: '100px',
                    },
                  })}
                />
              </div>
              {row.original.hasReference && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginRight: '8px' }}>Ref:</span>
                  {' \xa0\xa0 '}
                  <TextInput
                    disabled
                    value={row.original?.reference?.PrimaryRefSkuCostEstimation?.total_value ?? ''}
                    styles={(theme) => ({
                      input: {
                        width: '100px',
                      },
                    })}
                  />
                </div>
              )}
              {row.original.hasReference && (
                <span style={{ marginRight: '8px' }}>
                  Var:{' \xa0\xa0 '}{' '}
                  <span style={variance === '0.00' ? {} : { color: 'red' }}>
                    {Math.abs(parseFloat(variance))}
                  </span>
                </span>
              )}
            </div>
          );
        },
      },
      {
        id: 'curr_excel',
        header: 'Curr',
        grow: false,
        minSize: 30,
        size: 30,
        maxSize: 30,
        Cell: ({ row }) => {
          if (row?.original?.isReference)
            return (
              <>
                <span>Ref:{row.original?.currency}</span>
              </>
            );
          return (
            <>
              <div>
                <span>Main: {row.original?.currency}</span>
              </div>
              <div>
                {row.original.hasReference && (
                  <span>
                    Ref:{'\xa0\xa0\xa0'}
                    {row.original?.reference?.currency}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        id: 'price_excel',
        header: 'Price',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          const { diff, variance } = checkDiff(
            row.original?.RequestedSkuCostEstimation?.price,
            row.original?.reference?.PrimaryRefSkuCostEstimation?.price,
          );
          const refPrice =
            row.original?.reference?.PrimaryRefSkuCostEstimation?.price ||
            row.original?.reference?.PrimaryRefSkuCostEstimation?.Price;
          const mainPrice =
            row.original?.RequestedSkuCostEstimation?.price ||
            row.original?.RequestedSkuCostEstimation?.Price;
          if (row.original.isReference)
            return (
              <>
                <span>
                  Ref:
                  {row.original?.PrimaryRefSkuCostEstimation?.price ||
                    row.original?.PrimaryRefSkuCostEstimation?.Price}
                </span>
              </>
            );
          return (
            <>
              <div>
                <span>Main: {mainPrice}</span>
              </div>
              <div>
                {row.original.hasReference && (
                  <span>
                    Ref:{'\xa0\xa0\xa0'}
                    {refPrice}
                  </span>
                )}
              </div>
              {row.original.hasReference && (
                <span style={{ marginRight: '8px' }}>
                  Var:{' \xa0\xa0\xa0 '}{' '}
                  <span style={variance === '0.00' ? {} : { color: 'red' }}>
                    {Math.abs(parseFloat(variance))}
                  </span>
                </span>
              )}
            </>
          );
        },
      },
      {
        id: 'price_unit_excel',
        header: 'Price Unit',
        grow: false,
        minSize: 40,
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          if (row.original.isReference)
            return (
              <>
                <span>Ref:{row.original?.PrimaryRefSkuCostEstimation?.un}</span>
              </>
            );
          return (
            <>
              <div>
                <span>Main: {row.original?.RequestedSkuCostEstimation?.un}</span>
              </div>
              <div>
                {row.original.hasReference && (
                  <span>
                    Ref:{'\xa0\xa0\xa0'}
                    {row.original?.reference?.PrimaryRefSkuCostEstimation?.un}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
      {
        id: 'price_per_unit_excel',
        header: 'Price Per Unit',
        grow: false,
        minSize: 30,
        size: 30,
        maxSize: 30,
        Cell: ({ row }) => {
          if (row.original.isReference)
            return (
              <>
                <span>Ref:{row.original?.PrimaryRefSkuCostEstimation?.price_unit}</span>
              </>
            );
          return (
            <>
              <div>
                <span>Main: {row.original?.RequestedSkuCostEstimation?.price_unit}</span>
              </div>
              <div>
                {row.original.hasReference && (
                  <span>
                    Ref:{'\xa0\xa0\xa0'}
                    {row.original?.reference?.PrimaryRefSkuCostEstimation?.price_unit}
                  </span>
                )}
              </div>
            </>
          );
        },
      },
    ],
    [baseSkus, activePlant],
  );

  const Footer = () => (
    <FooterContainer>
      <Button
        onClick={() => handleSubmit(componentsDict)}
        variant='gradient'
        radius='md'
        size='md'
        fullWidth
        disabled={btnDisable || isCancelled}
      >
        Submit
      </Button>
    </FooterContainer>
  );
  const rightSideBtns = [
    {
      button: (
        <Button
          disabled={btnDisable || isCancelled}
          variant='light'
          color='gray.0'
          onClick={() => fetch(true)}
        >
          <IconRefresh color='black' variant='filled' size={16} />
        </Button>
      ),
    },
    {
      button: (
        <Button
          variant='light'
          size='sm'
          color='blue.3'
          disabled={btnDisable || isCancelled}
          styles={(theme) => ({
            label: {
              color: theme.colors.blue[6],
            },
          })}
          onClick={() => handleAutoAdjustment()}
        >
          Auto Adjustment of BOM
        </Button>
      ),
    },
  ];

  const leftButtons = [
    {
      button: (
        <Switch
          label='View Flagged Data'
          onChange={(event) => {
            setSwitchState(event.target.checked);
          }}
          classNames={{ input: classes.input, thumb: classes.thumb, track: classes.track }}
        />
      ),
    },
  ];
  const initialState = {
    columnPinning: {
      left: ['actions', 'componentNumber', 'component_description'],
      right: [],
    },
    sorting: [
      {
        id: 'component_description',
        desc: false,
      },
    ],
  };
  return (
    <>
      <ContentPaper footer={<Footer />}>
        <ProjectOverview />
        <Space h='sm' />
        <Tabs value={activePlant} onTabChange={(value) => setActivePlant(value)} color='yellow'>
          <Tabs.List>
            {producing_plant_data.map(({ producing_plant, id }, index) => (
              <Tabs.Tab value={producing_plant} className='menu-tabs'>
                {producing_plant}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          {producing_plant_data.map(({ producing_plant, id }, index) => {
            return (
              <Tabs.Panel value={producing_plant}>
                <MantineTable
                  columns={columns}
                  initialData={baseSkus.filter(
                    (item) =>
                      (item?.producingPlant?.producing_plant === producing_plant || item.isReference) &&
                      (!switchState || (switchState && item.flagged)),
                  )}
                  enablePinning
                  initialState={initialState}
                  unique={'indexNo'}
                  enableRowSelection={false}
                  loading={loading}
                  showSelectedToggle={false}
                  hideSelectColumn={true}
                  showResetAll={false}
                  enableExport={true}
                  pageSize={10}
                  enableSelectAll={false}
                  rightButtons={rightSideBtns}
                  leftButtons={leftButtons}
                />
              </Tabs.Panel>
            );
          })}
        </Tabs>
      </ContentPaper>
    </>
  );
};
